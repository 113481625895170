export const DispositionsMixin = {
    methods: {
        getDisposition(disposition) {
            switch (disposition) {
                case "WLH":
                    return "ABC";
                case "LWH":
                    return "BAC";
                case "HWL":
                    return "CAB";
                case "WHL":
                    return "ACB";
                case "LHW":
                    return "BCA";
                case "HLW":
                    return "CBA";
                default:
                    return disposition;
            }
        },
    }
}