<template>
  <div>
    <p>
      <b> {{ $t("ratio") }}:</b> {{ result.ratio }}
    </p>
    <p>
      <b>{{ $t("units") }}:</b> {{ result.units }}
    </p>
    <p>
      <b>{{ $t("calculated_width") }}:</b> {{ result.calculatedW }} / {{ result.article.width }}
    </p>
    <p>
      <b>{{ $t("calculated_length") }}:</b> {{ result.calculatedL }} / {{ result.article.length }}
    </p>
    <p>
      <b>{{ $t("calculated_height") }}:</b> {{ result.calculatedH }} / {{ result.article.height }}
    </p>
  </div>
</template>

<script>
export default {
  name: "AnalysisDetailsContentResultInfo",
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
};
</script>