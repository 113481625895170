<template>
  <div class="md-layout">
    <div
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
      "
    >
      <!-- box -->
      <select
        class="md-layout-item md-size-100"
        v-if="uniques.boxes"
        v-model="filter.box"
      >
        <option value="">--</option>
        <option
          v-for="box in uniques.boxes"
          :key="box.reference"
          :value="box.reference"
        >
          {{ box.reference }} {{ cutText(box.description, 40) }}
        </option>
      </select>
    </div>
    <div
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
      "
    >
      <!-- article -->
      <select
        class="md-layout-item md-size-100"
        v-if="uniques.articles"
        v-model="filter.article"
      >
        <option value="">--</option>
        <option
          v-for="article in uniques.articles"
          :key="article.reference"
          :value="article.reference"
        >
          {{ article.reference }} {{ article.description }}
        </option>
      </select>
    </div>
    <div
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
      "
    >
      <!-- ratio -->
      <select class="md-layout-item md-size-25" v-model="filter.ratio.type">
        <option value="gt">&gt;</option>
        <option value="lt">&lt;</option>
        <option value="=">=</option>
      </select>
      <input
        class="md-layout-item md-size-75"
        type="text"
        :placeholder="$t('ratio')"
        v-model="filter.ratio.quantity"
      />
    </div>
    <div
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
      "
    >
      <!-- disposition -->
      <select
        class="md-layout-item md-size-100"
        v-if="uniques.dispositions"
        v-model="filter.disposition"
      >
        <option value="">--</option>
        <option
          v-for="disposition in uniques.dispositions"
          :key="disposition"
          :value="disposition"
        >
          {{ getDisposition(disposition) }}
        </option>
      </select>
    </div>
    <div
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
        md-layout
      "
    >
      <!-- units -->
      <select class="md-layout-item md-size-25" v-model="filter.units.type">
        <option value="gt">&gt;</option>
        <option value="lt">&lt;</option>
        <option value="=">=</option>
      </select>
      <input
        class="md-layout-item md-size-75"
        type="text"
        :placeholder="$t('units')"
        v-model="filter.units.quantity"
      />
    </div>
    <div
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
      "
    >
      <!-- rows -->
      <select class="md-layout-item md-size-25" v-model="filter.rows.type">
        <option value="gt">&gt;</option>
        <option value="lt">&lt;</option>
        <option value="=">=</option>
      </select>
      <input
        class="md-layout-item md-size-75"
        type="text"
        :placeholder="$t('rows')"
        v-model="filter.rows.quantity"
      />
    </div>
    <div
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
      "
    >
      <!-- colums -->
      <select class="md-layout-item md-size-25" v-model="filter.columns.type">
        <option value="gt">&gt;</option>
        <option value="lt">&lt;</option>
        <option value="=">=</option>
      </select>
      <input
        class="md-layout-item md-size-75"
        type="text"
        :placeholder="$t('columns')"
        v-model="filter.columns.quantity"
      />
    </div>
    <div
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
      "
    >
      <!-- levels -->
      <select class="md-layout-item md-size-25" v-model="filter.levels.type">
        <option value="gt">&gt;</option>
        <option value="lt">&lt;</option>
        <option value="=">=</option>
      </select>
      <input
        class="md-layout-item md-size-75"
        type="text"
        :placeholder="$t('levels')"
        v-model="filter.levels.quantity"
      />
    </div>
    <div
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
      "
    >
      <!-- profile -->
      <select
        class="md-layout-item md-size-100"
        v-if="uniques.profiles"
        v-model="filter.packingProfile"
      >
        <option value="">--</option>
        <option
          v-for="profile in uniques.profiles"
          :key="profile.name"
          :value="profile.name"
        >
          {{ profile.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { ANALYSIS_DETAILS_UPDATE_MANAGED } from "../../store/actions.types";
import { DispositionsMixin } from "../../common/mixins/DispositionsMixin";
import { TextMixin } from "../../common/mixins/TextMixin";
export default {
  name: "AnalysisDetailsTitleFilter",
  mixins: [DispositionsMixin, TextMixin],
  computed: {
    ...mapGetters({
      analysis: "getAnalysisDetails",
      uniques: "getAnalysisDetailsUniques",
    }),
  },
  data() {
    return {
      filter: {
        box: "",
        article: "",
        ratio: {
          type: "gt",
        },
        disposition: "",
        units: {
          type: "gt",
        },
        rows: {
          type: "gt",
        },
        columns: {
          type: "gt",
        },
        levels: {
          type: "gt",
        },
        packingProfile: "",
      },
    };
  },

  watch: {
    filter: {
      deep: true,
      handler: function () {
        this.onFilterChange();
      },
    },
  },

  methods: {
    onFilterChange: _.debounce(function () {
      this.onFilter();
    }, 500),

    onFilter() {
      let filtered;
      filtered = _.filter(this.analysis.results, this.filterBox);
      filtered = _.filter(filtered, this.filterArticle);
      filtered = _.filter(filtered, this.filterRatio);
      filtered = _.filter(filtered, this.filterDisposition);
      filtered = _.filter(filtered, this.filterUnits);
      filtered = _.filter(filtered, this.filterRows);
      filtered = _.filter(filtered, this.filterColumns);
      filtered = _.filter(filtered, this.filterLevels);
      filtered = _.filter(filtered, this.filterProfile);
      this.$store.dispatch(ANALYSIS_DETAILS_UPDATE_MANAGED, filtered);
      this.$emit("onFilter");
    },

    filterBox(result) {
      return this.filterText(result, "box");
    },
    filterArticle(result) {
      return this.filterText(result, "article");
    },
    filterRatio(result) {
      return this.filterNumber(result, "ratio");
    },

    filterDisposition(result) {
      // No hay filtro, siempre se muestra
      if (!this.filter.disposition) return true;

      if (result.child.length > 0) {
        let b = false;
        result.child.forEach((child) => {
          if (this.filterDisposition(child)) b = true;
        });
        return b;
      } else if (result.disposition) {
        // Recursivo
        return result.disposition === this.filter.disposition;
      }

      return false;
    },

    filterUnits(result) {
      return this.filterNumber(result, "units");
    },
    filterRows(result) {
      return this.filterNumber(result, "rows");
    },
    filterColumns(result) {
      return this.filterNumber(result, "columns");
    },
    filterLevels(result) {
      return this.filterNumber(result, "levels");
    },
    filterProfile(result) {
      return this.filterText(result, "packingProfile");
    },

    filterNumber(result, field) {
      if (
        this.filter[field].quantity == "" ||
        this.filter[field].quantity == undefined
      ) {
        return true;
      }
      switch (this.filter[field].type) {
        case "=":
          return result[field] == this.filter[field].quantity;
        case "lt":
          return result[field] <= this.filter[field].quantity;
        case "gt":
        default:
          return result[field] >= this.filter[field].quantity;
      }
    },

    filterText(result, field) {
      if (this.filter[field] === "") {
        return true;
      }

      switch (field) {
        case "box":
        case "article":
          return this.filter[field] === result[field].reference;
        case "packingProfile":
          return this.filter[field] === result[field].name;
        default:
          return this.filter[field] === result[field];
      }
    },
  },
};
</script>

<style scoped>
.title-btn {
  padding: 0 5px;
}
select {
  padding: 7.5px 0;
}
input {
  padding: 8px 0;
}
select,
input {
  border: 0;
  border-bottom: 1px solid gray;
  text-align: center;
}
</style>