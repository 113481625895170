<template>
  <div class="md-layout list-titles md-small-hide">
    <md-button
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
      "
      @click="sort('box.reference')"
    >
      {{ $t("box") }}
      <font-awesome-icon
        v-if="sortBy === 'box.reference'"
        class="arrow-icon"
        :icon="sortType === 'asc' ? 'chevron-up' : 'chevron-down'"
      />
    </md-button>
    <md-button
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
      "
      @click="sort('article.reference')"
    >
      {{ $t("article") }}
      <font-awesome-icon
        v-if="sortBy === 'article.reference'"
        class="arrow-icon"
        :icon="sortType === 'asc' ? 'chevron-up' : 'chevron-down'"
      />
    </md-button>
    <md-button
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
      "
      @click="sort('ratio')"
    >
      {{ $t("ratio") }}
      <font-awesome-icon
        v-if="sortBy === 'ratio'"
        class="arrow-icon"
        :icon="sortType === 'asc' ? 'chevron-up' : 'chevron-down'"
      />
    </md-button>
    <div class="md-layout-item md-size-10 md-medium-size-33 md-small-size-50">
      <md-button class="title-btn" @click="sort('disposition')">
        {{ $t("disposition") }}
        <font-awesome-icon
          v-if="sortBy === 'disposition'"
          class="arrow-icon"
          :icon="sortType === 'asc' ? 'chevron-up' : 'chevron-down'"
        />
      </md-button>
      <packing-dispositions-help class="disposition-help" />
    </div>

    <md-button
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
      "
      @click="sort('units')"
    >
      {{ $t("units") }}
      <font-awesome-icon
        v-if="sortBy === 'units'"
        class="arrow-icon"
        :icon="sortType === 'asc' ? 'chevron-up' : 'chevron-down'"
      />
    </md-button>
    <md-button
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
      "
      @click="sort('rows')"
    >
      {{ $t("rows") }}
      <font-awesome-icon
        v-if="sortBy === 'rows'"
        class="arrow-icon"
        :icon="sortType === 'asc' ? 'chevron-up' : 'chevron-down'"
      />
    </md-button>
    <md-button
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
      "
      @click="sort('columns')"
    >
      {{ $t("columns") }}
      <font-awesome-icon
        v-if="sortBy === 'columns'"
        class="arrow-icon"
        :icon="sortType === 'asc' ? 'chevron-up' : 'chevron-down'"
      />
    </md-button>
    <md-button
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
      "
      @click="sort('levels')"
    >
      {{ $t("levels") }}
      <font-awesome-icon
        v-if="sortBy === 'levels'"
        class="arrow-icon"
        :icon="sortType === 'asc' ? 'chevron-up' : 'chevron-down'"
      />
    </md-button>
    <md-button
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50
        title-btn
      "
      @click="sort('profile.name')"
    >
      {{ $t("profile") }}
      <font-awesome-icon
        v-if="sortBy === 'profile.name'"
        class="arrow-icon"
        :icon="sortType === 'asc' ? 'chevron-up' : 'chevron-down'"
      />
    </md-button>

    <md-button
      class="
        md-layout-item md-size-10 md-medium-size-33 md-small-size-50 md-raised
        title-btn
      "
      @click="showFilter = !showFilter"
    >
      {{ $t("filters") }}
      <font-awesome-icon
        class="arrow-icon"
        :icon="showFilter ? 'chevron-up' : 'chevron-down'"
      />
    </md-button>
    <analysis-details-title-filter @onFilter="onFilter" v-show="showFilter" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { ANALYSIS_DETAILS_SORT } from "../../store/actions.types";
import AnalysisDetailsTitleFilter from "./AnalysisDetailsTitleFilter.vue";
import PackingDispositionsHelp from "../utils/PackingDispositionsHelp.vue";
export default {
  name: "AnalysisDetailsContentResultTitles",
  components: {
    AnalysisDetailsTitleFilter,
    PackingDispositionsHelp,
  },

  computed: {
    ...mapGetters({
      analysis: "getAnalysisDetailsManagedResults",
    }),
  },

  data() {
    return {
      uniqueArticles: new Set(),
      uniqueBoxes: new Set(),
      sortBy: "",
      sortType: "",
      showFilter: false,
    };
  },

  methods: {
    sort(sortBy, orderAgain = false) {
      if (!orderAgain) {
        if (this.sortBy === sortBy) {
          if (this.sortType === "desc") {
            this.sortType = "asc";
          } else {
            this.sortType = "desc";
          }
        } else {
          this.sortType = "desc";
        }
      }

      this.sortBy = sortBy;
      this.$store.dispatch(
        ANALYSIS_DETAILS_SORT,
        _.orderBy(this.analysis, [this.sortBy], [this.sortType])
      );
    },

    onFilter() {
      if (this.sortBy !== "") {
        this.sort(this.sortBy, true);
      }
    },
  },
};
</script>

<style>
.list-titles {
  position: sticky;
  background: white;
  padding: 15px 0;
  top: 64px;
  text-align: center;
  font-weight: bold;
  z-index: 3;
  border-bottom: solid 1px #f5f5f5;
}
.title-btn {
  margin: 0;
}
.arrow-icon {
  margin-left: 5px;
  margin-bottom: -0.5px;
}
.disposition-help {
  margin-top: 9px;
}
</style>