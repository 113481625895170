<template>
  <div class="md-layout">
    <analysis-details-content-result-items
      class="md-layout-item md-size-100 result"
      :level="level"
      :result="result"
    />

    <!-- Child results -->
    <analysis-details-content-result-child
      class="list-item md-layout-item md-size-100"
      v-for="(result2, key) in result.child"
      :key="key"
      :result="result2"
      :level="level + 1"
    />
  </div>
</template>

<script>
import AnalysisDetailsContentResultItems from "./AnalysisDetailsContentResultItems.vue";
export default {
  name: "AnalysisDetailsContentResultChild",
  components: {
    AnalysisDetailsContentResultItems,
  },
  props: {
    level: {
      type: Number,
      required: true,
    },
    result: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.result {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid rgb(199, 199, 199);
}
</style>