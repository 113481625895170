<template>
  <div>
    <md-button
      class="md-raised md-icon-button mg-left-0 export"
      @click="exportCsv"
    >
      <font-awesome-icon icon="file-download" />
      <md-tooltip>{{ $t("export_csv") }}</md-tooltip>
    </md-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateMixin } from "../../common/mixins/DateMixin";
import { FilesUtils } from "../../common/utils/filesUtils";
export default {
  name: "AnalysisDetailsCsv",
  mixins: [DateMixin],
  computed: {
    ...mapGetters({
      analysis: "getAnalysisDetails",
    }),
  },

  methods: {
    exportCsv() {
      let txt =
        "INDEX;BOX_REFERENCE;BOX_DESCRIPTION;ARTICLE_REFERENCE;ARTICLE_DESCRIPTION;RATIO;DISPOSITION;UNITS;ROWS;COLUMNS;LEVELS;PACKING_PROFILE\n";

      txt = this.manageResults(this.analysis.results, txt, 0);

      let fileName = `volscan ${this.formatDateTime(
        this.analysis.createdDate
      )}.csv`;
      FilesUtils.download(txt, fileName);
    },

    manageResults(results, currentTxt, index) {
      results.forEach((result) => {
        currentTxt = this.manageResult(result, currentTxt, index);
      });

      return currentTxt;
    },

    manageResult(result, currentTxt, index) {
      let boxRef = result.box ? result.box.reference : "";
      let boxDescription =
        result.box && result.box.description ? result.box.description : "";
      let articleReference = result.article ? result.article.reference : "";
      let articleDescription =
        result.article && result.article.description
          ? result.article.description
          : "";
      let ratio = Number.parseFloat(result.ratio).toFixed(2);
      let disposition = result.disposition ? result.disposition : "";
      let units = result.units;
      let rows = result.rows;
      let columns = result.columns;
      let levels = result.levels;
      let packingProfileName = result.packingProfile
        ? result.packingProfile.name
        : "";

      currentTxt += `${index};${boxRef};${boxDescription};${articleReference};${articleDescription};${ratio};${disposition};${units};${rows};${columns};${levels};${packingProfileName}\n`;

      if (result.child) {
        result.child.forEach((child) => {
          currentTxt = this.manageResult(child, currentTxt, index + 1);
        });
      }

      return currentTxt;
    },
  },
};
</script>
