<template>
  <div>
    <md-button
      class="md-raised md-icon-button mg-left-0"
      :to="{ name: 'Analysis' }"
    >
      <font-awesome-icon icon="arrow-left" />
    </md-button>

    <md-button class="md-raised md-accent md-icon-button" @click="remove">
      <font-awesome-icon icon="trash" />
    </md-button>

    <md-progress-bar v-if="loading" md-mode="indeterminate" />
    <analysis-details-content v-else/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Analysis } from "../../common/api/api.service";
import AnalysisDetailsContent from "../../components/analysis/AnalysisDetailsContent.vue";
import {
  ALERT,
  ANALYSIS_DETAILS_LOAD,
  ANALYSIS_DETAILS_UNLOAD,
  CONFIRM_SHOW,
  TOOLBAR_TITLE,
} from "../../store/actions.types";
export default {
  name: "AnalysisDetails",
  components: {
    AnalysisDetailsContent,
  },
  computed: {
    ...mapGetters({
      analysis: "getAnalysisDetails",
    }),
  },

  data() {
    return {
      loading: true,
    };
  },

  mounted() {
    this.$store.dispatch(TOOLBAR_TITLE, "analysis_details");
    this.$store
      .dispatch(ANALYSIS_DETAILS_LOAD, this.$route.params.id)
      .then(() => {
        this.loading = false;
      })
      .catch((err) => {
        console.log(err.response.status);
        if(err.response?.status === 404) {
          this.$store.dispatch(ALERT, {message: "analysis_not_found"});
          this.$router.replace({ name: "Analysis" });
        } else {
          this.$store.dispatch(ALERT, {message: "error"});
        }
      });
  },

  beforeDestroy() {
    this.$store.dispatch(ANALYSIS_DETAILS_UNLOAD);
  },

  methods: {
    remove() {
      this.$store.dispatch(CONFIRM_SHOW, {
        warning: true,
        title: "remove?",
        confirm: "remove",
        cancel: "cancel",
        onConfirm: () => {
          Analysis.delete(this.$route.params.id).then(() => {
            this.$router.push({ name: "Analysis" });
          });
        },
      });
    },
  },
};
</script>
