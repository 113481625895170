<template>
  <div v-if="analysis && analysis.id">
    <p class="md-title">
      <span v-if="analysis.name"> {{ analysis.name }} </span>
      <i v-else> {{ $t("no_name") }} </i>
      <font-awesome-icon
        icon="edit"
        class="seleccionable"
        @click="updateName"
      />
    </p>
    <p>
      {{ formatDateTime(analysis.createdDate) }}
    </p>

    <div class="md-layout">
      <span class="md-layout-item md-size-10 md-medium-size-33">
        {{ $t("articles") }}: {{ obtainArticleQty() }}
      </span>
      <span class="md-layout-item md-size-10 md-medium-size-33">
        {{ $t("boxes") }}: {{ obtainBoxesQty() }}
      </span>
      <span class="md-layout-item md-size-80 md-medium-size-33"
        >{{ $t("profiles") }}: {{ obtainProfilesQty() }}
      </span>

      <div class="md-layout">
        <span class="md-layout-item md-size-10 md-medium-size-40"
          >{{ $t("results") }}: {{ analysis.results.length }}
        </span>
        <span class="md-layout-item md-size-80 md-medium-size-40">
          {{ $t("filtered") }}: {{ managedResults.length }}
        </span>
        <analysis-details-csv class="md-layout-item md-size-10 export" />
      </div>
    </div>

    <!-- Títulos y filtros -->
    <analysis-details-content-result-titles />

    <md-list
      v-if="managedResults && managedResults.length > 0"
      ref="result-list"
    >
      <virtual-list
        :style="`height: ${height}px; overflow-y: auto`"
        :data-key="'id'"
        :data-sources="managedResults"
        :data-component="resultComponent"
      />
    </md-list>
    <div v-else>
      {{ $t("no_results") }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateMixin } from "../../common/mixins/DateMixin";
import {
  ANALYSIS_DETAILS_UNIQUE_LOAD,
  PROMPT_SHOW,
} from "../../store/actions.types";
import AnalysisDetailsContentResult from "./AnalysisDetailsContentResult.vue";
import AnalysisDetailsContentResultTitles from "./AnalysisDetailsContentResultTitles.vue";
import AnalysisDetailsCsv from "./AnalysisDetailsCsv.vue";
import VirtualList from "vue-virtual-scroll-list";
import { Analysis } from "../../common/api/api.service";

export default {
  name: "AnalysisDetailsContent",
  components: {
    "virtual-list": VirtualList,
    // AnalysisDetailsContentResult,
    AnalysisDetailsContentResultTitles,
    AnalysisDetailsCsv,
  },
  mixins: [DateMixin],
  computed: {
    ...mapGetters({
      analysis: "getAnalysisDetails",
      managedResults: "getAnalysisDetailsManagedResults",
    }),
  },

  data() {
    return {
      uniqueArticles: [],
      uniqueBoxes: [],
      uniqueProfiles: [],
      uniqueDispositions: [],
      height: 250,
      resultComponent: AnalysisDetailsContentResult,
    };
  },

  beforeMount() {
    // Agrupar datos únicos para utilizar en los filtros
    let articlesRef = new Set();
    let boxesRef = new Set();
    let profilesName = new Set();
    let dispositions = new Set();

    this.analysis.results.forEach((result) => {
      if (!articlesRef.has(result.article.reference)) {
        articlesRef.add(result.article.reference);
        this.uniqueArticles.push(result.article);
      }
      if (!boxesRef.has(result.box.reference)) {
        boxesRef.add(result.box.reference);
        this.uniqueBoxes.push(result.box);
      }
      if (!profilesName.has(result.packingProfile.name)) {
        profilesName.add(result.packingProfile.name);
        this.uniqueProfiles.push(result.packingProfile);
      }

      this.obtainDisposition(result).forEach((disposition) => {
        if (!dispositions.has(disposition)) {
          dispositions.add(disposition);
          this.uniqueDispositions.push(disposition);
        }
      });
    });

    let unique = {
      articles: this.uniqueArticles,
      boxes: this.uniqueBoxes,
      profiles: this.uniqueProfiles,
      dispositions: this.uniqueDispositions,
    };

    this.$store.dispatch(ANALYSIS_DETAILS_UNIQUE_LOAD, unique);
  },

  mounted() {
    this.calculateHeight();
    window.addEventListener("resize", this.calculateHeight);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.calculateHeight);
  },

  methods: {
    obtainArticleQty() {
      return this.uniqueArticles.length;
    },

    obtainBoxesQty() {
      return this.uniqueBoxes.length;
    },

    obtainProfilesQty() {
      return this.uniqueProfiles.length;
    },

    calculateHeight() {
      if(this.$refs["result-list"]) {
        let min = this.$refs["result-list"].$el.getBoundingClientRect().top;
        let max = window.innerHeight;
        let size = max - min - 35;
  
        if (size < 250) {
          size = 250;
        }
  
        this.height = size;
      }
    },

    updateName() {
      this.$store.dispatch(PROMPT_SHOW, {
        title: "name",
        cancel: "cancel",
        confirm: "update",
        placeholder: "name",
        onConfirm: (name) => {
          Analysis.updateName(this.analysis.id, name)
            .then(() => {
              name = name.trim();
              this.analysis.name = name;
            })
            .catch(() => {
              this.$toast.error("Error");
            });
        },
      });
    },

    obtainDisposition(result) {
      let dispositions = new Set();

      if (result.child.length > 0) {
        result.child.forEach((child) => {
          let dispositionsTmp = this.obtainDisposition(child);

          dispositionsTmp.forEach((disposition) => {
            dispositions.add(disposition);
          });
        });
      }

      if (result.disposition) {
        dispositions.add(result.disposition);
      }

      return dispositions;
    },
  },
};
</script>

<style scoped>
.export {
  text-align: right;
  margin-top: -20px;
}
</style>