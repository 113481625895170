<template>
  <div class="md-layout">
    <span
      class="md-layout-item md-size-10 md-medium-size-33 md-small-size-50 box"
    >
      <b class="title"> {{ $t("box") }}: </b>
      <span :style="`padding-left: ${level * 20}px`">
        <md-icon v-if="level > 0">chevron_right</md-icon>
        <b> {{ level }} </b>
      </span>
      {{ result.box ? result.box.reference : "" }} <br />
      <span class="description" v-if="result.box">
        {{ cutText(result.box.description, 15) }}
      </span>
      <md-tooltip v-if="result.box && result.box.description">
        {{ result.box.description }}
      </md-tooltip>
    </span>

    <span class="md-layout-item md-size-10 md-medium-size-33 md-small-size-50">
      <b class="title"> {{ $t("article") }}:</b>
      {{ result.article ? cutText(result.article.reference, 18) : "" }} <br />
      <span v-if="result.article">
        {{ cutText(result.article.description, 14) }}
      </span>
      <md-tooltip
        v-if="
          result.article &&
          result.article.reference &&
          result.article.reference.length > 18
        "
      >
        {{ result.article.reference }}
      </md-tooltip>
    </span>

    <span class="md-layout-item md-size-10 md-medium-size-33 md-small-size-50">
      <b class="title"> {{ $t("ratio") }}:</b>
      <span v-if="level === 0">{{
        Number.parseFloat(result.ratio).toFixed(2)
      }}</span>
    </span>

    <span class="md-layout-item md-size-10 md-medium-size-33 md-small-size-50">
      <b class="title"> {{ $t("disposition") }}:</b>
      {{ getDisposition(result.disposition) }}
      <md-tooltip class="tooltip" v-if="result.disposition">
        <img
          class="tooltip-img"
          :src="getImageUrl(getDisposition(result.disposition))"
        />
      </md-tooltip>
    </span>

    <span class="md-layout-item md-size-10 md-medium-size-33 md-small-size-50">
      <b class="title"> {{ $t("units") }}:</b> {{ result.units }}
    </span>

    <span class="md-layout-item md-size-10 md-medium-size-33 md-small-size-50">
      <span v-if="level > 0">
        <b class="title"> {{ $t("rows") }}:</b> {{ result.rows }}
      </span>
    </span>

    <span class="md-layout-item md-size-10 md-medium-size-33 md-small-size-50">
      <span v-if="level > 0">
        <b class="title"> {{ $t("columns") }}:</b> {{ result.columns }}</span
      >
    </span>

    <span class="md-layout-item md-size-10 md-medium-size-33 md-small-size-50">
      <span v-if="level > 0"
        ><b class="title"> {{ $t("levels") }}:</b> {{ result.levels }}</span
      >
    </span>

    <span class="md-layout-item md-size-10 md-medium-size-33 md-small-size-50">
      <b class="title"> {{ $t("profile") }}:</b>
      <span v-if="result.packingProfile">
        {{ result.packingProfile.name }}
      </span>
    </span>
  </div>
</template>

<script>
import { TextMixin } from "../../common/mixins/TextMixin";
import { DispositionsMixin } from "../../common/mixins/DispositionsMixin";
export default {
  name: "AnalysisDetailsContentResultItems",
  mixins: [TextMixin, DispositionsMixin],
  props: {
    result: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },

  methods: {
    getImageUrl(disposition) {
      return require(`../../assets/help/disp/${disposition}.jpeg`);
    },
  },
};
</script>

<style scoped>
.tooltip,
.tooltip-img {
  height: 200px;
}
.tooltip-img {
  padding-bottom: 4px;
}
.tooltip {
  padding: 2px;
}
@media (min-width: 959px) {
  .title {
    display: none;
  }
}
.child {
  padding-left: 10px;
}
.box {
  text-align: left;
}
</style>