export const FilesUtils = {
    /**
     * Función para generar un fichero a partir del texto
     * @param {String} content Texto que tendrá el contenido descargado
     * @param {String} fileName Nombre del fichero
     * @param {String} contentType Tipo de fichero (default 'text/plain; charset=utf-8')
     */
    download(content, fileName, contentType = 'text/plain; charset=utf-8') {
        var a = document.createElement("a");
        var file = new Blob([content], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }
}
