<template>
  <md-list-item
    md-expand
    :md-expanded.sync="expanded"
    :class="{ expanded: expanded }"
  >
    <div class="md-layout list-item">
      <analysis-details-content-result-items
        class="list-item md-layout-item md-size-100"
        :level="level"
        :result="source"
      />
      <analysis-details-content-result-child
        class="list-item md-layout-item md-size-100"
        v-for="(result2, key) in source.child"
        :key="key"
        :result="result2"
        :level="level + 1"
      />
    </div>

    <div slot="md-expand">
      <md-tabs v-if="expanded">
        <md-tab :md-label="$t('result')">
          <analysis-details-content-result-info :result="source" />
        </md-tab>

        <md-tab :md-label="$t('box')">
          <box-form :box="source.box" :readOnly="true" />
        </md-tab>

        <md-tab :md-label="$t('article')">
          <article-form :article="source.article" :readOnly="true" />
        </md-tab>

        <md-tab :md-label="$t('packing_profile')">
          <packing-profile-form
            :profile="source.packingProfile"
            :readOnly="true"
          />
        </md-tab>
      </md-tabs>
    </div>
  </md-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import { TextMixin } from "../../common/mixins/TextMixin";
import { DispositionsMixin } from "../../common/mixins/DispositionsMixin";
import ArticleForm from "../article/ArticleForm.vue";
import BoxForm from "../box/BoxForm.vue";
import PackingProfileForm from "../packingProfile/PackingProfileForm.vue";
import AnalysisDetailsContentResultInfo from "./AnalysisDetailsContentResultInfo.vue";
import AnalysisDetailsContentResultItems from "./AnalysisDetailsContentResultItems.vue";
import AnalysisDetailsContentResultChild from "./AnalysisDetailsContentResultChild.vue";
export default {
  name: "AnalysisDetailsContentResult",
  mixins: [TextMixin, DispositionsMixin],
  components: {
    AnalysisDetailsContentResultInfo,
    PackingProfileForm,
    ArticleForm,
    BoxForm,
    AnalysisDetailsContentResultItems,
    AnalysisDetailsContentResultChild,
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      analysis: "getAnalysisDetails",
    }),
  },

  data() {
    return {
      expanded: false,
    };
  },

  methods: {
    getImageUrl(disposition) {
      return require(`../../assets/help/disp/${disposition}.jpeg`);
    },
  },
};
</script>

<style scoped>
.md-list-item:nth-child(odd) {
  background: #f5f5f5;
}
.md-list-item {
  padding: 5px 0;
}
.expanded >>> .md-tabs-navigation,
.expanded >>> .md-tab,
.expanded {
  background: #e8e8e8;
}

.list-item {
  width: 100%;
  align-items: center;
}

.description {
  padding-top: 5px;
}

@media (min-width: 959px) {
  .title {
    display: none;
  }
  .list-item {
    text-align: center;
  }
}
</style>
